<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <div class="app-header__content">
    <app-recent-search-select *ngIf="selected && !globals.toggleHeaderMobile" class="select-scope"
      headerClass="select-scope__title" [selected]="selected" [recentChoices]="recentCustomers"
      (selectionChange)="selectCustomer($event)" (searchChange)="searchCustomer($event)"
      [foundChoices]="foundCustomers">
    </app-recent-search-select>
    <button *ngIf="showCustomerSettings" mat-icon-button class="app-header__content__customer-details" (click)="navigateToCurrentCustomer()">
      <mat-icon>settings</mat-icon>
    </button>
    <div class="app-header-left">
    </div>
    <div class="app-header-right">
      <app-license-status class="hide-mobile"></app-license-status>
      <app-guides class="hide-mobile"></app-guides>
      <app-notifications *cmnIfPerm="['cmn:notification-recipient:read']" ></app-notifications>
      <app-dots></app-dots>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <app-recent-search-select *ngIf="selected" [selected]="selected" class="app-header__mobile-menu__select-scope"
      headerClass="select-scope__title" [recentChoices]="recentCustomers" (selectionChange)="selectCustomer($event)"
      (searchChange)="searchCustomer($event)" [foundChoices]="foundCustomers">
    </app-recent-search-select>
    <button *ngIf="showCustomerSettings" mat-icon-button class="app-header__content__customer-details" (click)="navigateToCurrentCustomer()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
      <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
        (click)="toggleHeaderMobile()">
        <div class="btn-icon-wrapper">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </div>
      </button>
    </span>
  </div>
</div>
