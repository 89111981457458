<div class="device">
  <div class="device_header">
    <span class="device_header_title">{{ 'DEVICE.TITLE' | translate }}</span>
  </div>
  <div class="device_body">
    <div class="device-background"></div>
    <div class="device_body_sidebar">
      <div class="device_body_sidebar_buttons">
        <button *cmnIfPerm="['scr:device:create']" class="device_body_sidebar_buttons_add" mat-raised-button
          color="primary" id="openDeviceCreateDialogButton" (click)="openDeviceCreateDialog()">
          <mat-icon>add</mat-icon>{{ 'DEVICE.ACTIONS.ADD_DEVICE' | translate }}
        </button>
        <button *cmnIfPerm="['scr:device:create']" class="device_body_sidebar_buttons_actions" mat-raised-button
          id="openDeviceListActionsDialogButton" (click)="toggleSidebar()">
          <mat-icon *ngIf="showSidebar; else sidebarClosed">close</mat-icon>
          <ng-template #sidebarClosed>
            <mat-icon>more_horiz</mat-icon>
          </ng-template>
        </button>
      </div>
      <div class="device_body_sidebar_search-form_title" *ngIf="showSidebar">
        {{ 'DEVICE.ACTIONS.SEARCH' | translate | uppercase }}
      </div>
      <form [formGroup]="deviceSearch" class="device_body_sidebar_search-form" *ngIf="showSidebar">
        <mat-form-field appearance="fill">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text" matInput formControlName="search" (keyup)="onSearch($event)">
        </mat-form-field>
      </form>
      <div class="device_body_sidebar_scope">
        <div class="device_body_sidebar_scope_title" *ngIf="showSidebar">
          {{ 'DEVICE.ACTIONS.QUERY_SCOPE' | translate | uppercase }}
        </div>
        <mat-select class="device_body_sidebar_scope_select" [(value)]="selectedScope"
          (selectionChange)="onQueryScopeChange($event)" *ngIf="showSidebar">
          <mat-option *ngFor="let scope of QueryScopes" [value]="scope">
            {{ ("DEVICE.QUERY_SCOPE." + scope) | translate }}
          </mat-option>
        </mat-select>
      </div>

      <div class="device_body_sidebar_scope">
        <div class="device_body_sidebar_scope_title" *ngIf="showSidebar">
          {{ 'STATUS.SELECT.LABEL' | translate | uppercase }}
        </div>
        <ng-multiselect-dropdown *ngIf="showSidebar" class="device_body_sidebar_tag-dropdown"
          [placeholder]="'STATUS.SELECT.PLACEHOLDER' | translate" [settings]="dropdownStatusesSettings"
          [data]="statuses" (onSelect)="onStatusSelected($event)" (onDeSelect)="onStatusDeSelected($event)">
        </ng-multiselect-dropdown>
      </div>

      <div class="device_body_sidebar_tag" *ngIf="showSidebar">
        {{ 'DEVICE.FIELDS.TAGS.LABEL' | translate | uppercase }}
      </div>
      <ng-multiselect-dropdown name="tags" class="device_body_sidebar_tag-dropdown" *ngIf="showSidebar"
        [placeholder]="'TAG.SELECT.PLACEHOLDER' | translate" [settings]="dropdownTagsSettings" [data]="tags"
        (onSelect)="onTagSelected($event)" [(ngModel)]="tagsAdded" (onDeSelect)="onTagDeSelected($event)">
      </ng-multiselect-dropdown>

    </div>
    <div class="device_body_content">
      <div #listView class="device_body_content_list" *ngIf="viewMode == ViewMode.List;else gridView">
        <mat-table [dataSource]="deviceDataSource" matSort class="device_body_content_list humecloud-list">
          <!-- type -->
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DEVICE.LABEL.TYPE' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let device">
              <span class="mobile-label">{{ 'DEVICE.LABEL.TYPE' | translate }}</span>
              <div class="device_body_content_list_device-type">
                <div class="device_body_content_list_device-type_logo" [ngSwitch]="device.type">
                  <ng-container *ngSwitchCase="DeviceType.KIOSK">
                    <img class="mat-tooltip-trigger" src="/assets/images/device/kiosk.svg"
                      matTooltip="{{ 'DEVICE.TYPE.KIOSK' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_LG">
                    <img src="/assets/images/device/lg.svg" matTooltip="{{ 'DEVICE.TYPE.SCR_LG' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_ANDROID">
                    <img src="/assets/images/device/android.svg"
                      matTooltip="{{ 'DEVICE.TYPE.SCR_ANDROID' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_RASPBERRY_PI">
                    <img src="/assets/images/device/HR3-icon.svg"
                      matTooltip="{{ 'DEVICE.TYPE.SCR_RASPBERRY_PI' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_ELECTRON">
                    <img src="/assets/images/device/HR4-icon.svg"
                      matTooltip="{{ 'DEVICE.TYPE.SCR_ELECTRON' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_SCREENSAVER">
                    <img src="/assets/images/device/screensaver.svg"
                      matTooltip="{{ 'DEVICE.TYPE.SCR_SCREENSAVER' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchCase="DeviceType.SCR_TIZEN">
                    <img src="/assets/images/device/samsung-icon.svg"
                      matTooltip="{{ 'DEVICE.TYPE.SCR_TIZEN' | translate }}">
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <img src="/assets/images/device/x86.svg" matTooltip="{{ 'DEVICE.TYPE.SCR_X86' | translate }}">
                  </ng-container>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <!-- name -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DEVICE.LABEL.NAME' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let device">
              <div class="device_body_content_list_item">
                <div class="device_body_content_list_item_info">
                  <span class="mobile-label">{{ 'DEVICE.LABEL.NAME' | translate }}</span>
                  <span class="device_body_content_list_item_name">{{ device.name }}</span>
                </div>
                <span class="device_body_content_list_item_customer" *ngIf="selectedScope == 'CUSTOMER_AND_CHILDREN'">
                  {{deviceCustomerNames[device.customerId]}}
                </span>
              </div>
            </mat-cell>
          </ng-container>
          <!-- status -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DEVICE.LABEL.STATUS' | translate }} ({{ 'DEVICE.LABEL.CONTENT-STATUS' | translate }})
            </mat-header-cell>
            <mat-cell *matCellDef="let device">
              <app-device-status [device]="device"></app-device-status>
              <app-device-content-status [device]="device"></app-device-content-status>
              <app-device-tunnel-status *cmnIfPerm="['scr:device-action:read']"
                [device]="device"></app-device-tunnel-status>
            </mat-cell>
          </ng-container>
          <!-- actions -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let device">
              <button mat-icon-button *cmnIfPerm="['scr:device:update:unrestricted']"
                [routerLink]="['/devices', device.id]">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button *cmnIfPerm="['scr:device:delete']"
                (click)="doDelete($event, device)" class="deleteDeviceButton">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="openMenuButton">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="doPaired(device)">
                  <mat-icon>link</mat-icon>
                  <span>{{ 'DEVICE.BUTTONS.PAIRING' | translate }}</span>
                </button>
                <button mat-menu-item *cmnIfPerm="['scr:device-action:update']" (click)="doCreateAction(device.id)"
                  class="createActionButton">
                  <mat-icon>play_arrow</mat-icon>
                  <span>{{ 'DEVICE.BUTTONS.ACTION' | translate }}</span>
                </button>
                <button mat-menu-item (click)="doShowMonit($event, device)">
                  <mat-icon>info</mat-icon>
                  <span>{{ 'DEVICE.BUTTONS.MONITORING' | translate }}</span>
                </button>
                <button mat-menu-item *ngIf="device.type == 'KIOSK'" [routerLink]="['/devices', device.id, 'stats']">
                  <mat-icon>bar_chart</mat-icon>
                  <span>{{ 'DEVICE.BUTTONS.STATS' | translate }}</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
        </mat-table>
      </div>
      <ng-template #gridView>
        <mat-grid-list cols="6" gutterSize="10px" rowHeight="4:3">
          <mat-grid-tile *ngFor="let device of deviceDataSource.data">
            <mat-card>
              <mat-card-content>
                <div class="mat-card-content-img">
                  {{ "DEVICE.TYPE." + device.type | translate }}
                </div>
                <div class="mat-card-content-name">
                  <span>{{ device.name }}</span>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <button *cmnIfPerm="['scr:device:update:unrestricted'];op:'OR'" mat-button color="accent"
                  [routerLink]="['/devices/', device.id]">
                  <mat-icon>edit</mat-icon>
                </button>
                <button *cmnIfPerm="['scr:device:delete'];op:'OR'" mat-button color="accent"
                  (click)="doDelete($event, device)" class="deleteDeviceButton">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-template>
    </div>
  </div>
  <footer>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage()"></mat-paginator>
  </footer>
</div>
