import { OnInit, AfterViewInit, Component, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core"
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort, MatSortModule } from "@angular/material/sort"
import { MatTableDataSource } from "@angular/material/table"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { QueryScope, DeleteDialogComponent, SnackBarService, Tag, TagService, TargetType, CmnIfPermDirective } from "hcl-lib"
import { IDropdownSettings, NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"
import { DeviceService } from "projects/hcl-portal/src/app/common/services/device/device.service"
import { from, of, Subscription, EMPTY, Observable } from "rxjs"
import { catchError, mergeMap, toArray, tap, map, distinct } from "rxjs/operators"
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule } from "@angular/forms"
import {
  DeviceActionDialogComponent
} from "../../../Layout/Components/widget/device-action-dialog/device-action-dialog.component"
import { DeviceCreateDialogComponent } from "../device-create-dialog/device-create-dialog.component"
import { DeviceMonitDialogComponent } from "../device-monit-dialog/device-monit-dialog.component"
import { Device, DeviceType } from "projects/hcl-portal/src/app/common/interfaces/device"
import { DevicePairingDialogComponent } from "../device-pairing-dialog/device-pairing-dialog.component"
import { CustomerService } from "projects/hcl-lib/src/lib/services/customer/customer.service"
import { DeviceConnectionStatus } from "../../../../../../../hcl-lib/src/lib/interfaces/deviceConnectionStatus"
import { RouterModule } from "@angular/router"
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from "@angular/common"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatSelectChange, MatSelectModule } from "@angular/material/select"
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatTableModule } from "@angular/material/table"
import { DeviceStatusComponent } from "../../../Layout/Components/widget/device-status/device-status.component"
import { DeviceContentStatusComponent } from "../../../Layout/Components/widget/device-content-status/device-content-status.component"
import { DeviceTunnelStatusComponent } from "../../../Layout/Components/widget/device-tunnel-status/device-tunnel-status.component"
import { MatGridListModule } from "@angular/material/grid-list"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatMenuModule } from "@angular/material/menu"
import { MatCardModule } from "@angular/material/card"

export enum ViewMode {
  List = "List",
  Grid = "Grid"
}

@Component({
    selector: "app-device-list",
    templateUrl: "./device-list.component.html",
    styleUrls: ["./device-list.component.scss"],
    standalone: true,
    imports: [RouterModule, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatIconModule, MatTooltipModule, MatTableModule, MatSortModule, MatPaginatorModule, MatGridListModule, MatMenuModule, MatCardModule, TranslateModule, CmnIfPermDirective, NgMultiSelectDropDownModule, DeviceStatusComponent, DeviceContentStatusComponent, DeviceTunnelStatusComponent]
})
export class DeviceListComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private deviceService: DeviceService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private snackService: SnackBarService,
    private tagService: TagService,
    private customerService: CustomerService,
    private chRef: ChangeDetectorRef
  ) {
    // do nothing
  }

  subscriptions: Subscription = new Subscription()

  ViewMode = ViewMode
  viewMode: ViewMode = ViewMode.List
  DeviceType = DeviceType
  deviceDataSource = new MatTableDataSource<Device>()
  columnsToDisplay = ["type", "name", "status", "actions"]

  deviceSearch: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  })

  showSidebar = true
  dropdownTagsSettings: IDropdownSettings = {}
  dropdownStatusesSettings: IDropdownSettings = {}
  tags!: Tag[]
  tagsAdded: Tag[] = []
  statuses: { id: string, name: string }[] = Object.values(DeviceConnectionStatus).map(status => {
    return { id: status, name: this.translateService.instant("DEVICE.STATUS." + status) }
  })
  statusAdded: DeviceConnectionStatus[] = []

  selectedScope = QueryScope.CUSTOMER
  deviceCustomerNames: {[customerId: string]: string} = {}

  QueryScopes = [
    QueryScope.CUSTOMER,
    QueryScope.CUSTOMER_AND_CHILDREN
  ]

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: false }) sort!: MatSort

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.showSidebar = false
    }
    this.initializeStatusDropdownSettings()
  }

  ngAfterViewInit(): void {
    this.deviceDataSource.sort = this.sort
    this.retrieveDevices()

    this.initializeTagsDropdownSettings()

    this.subscriptions.add(this.tagService.getAvailableTags([TargetType.DEVICE]).subscribe(tags => {
      this.tags = tags
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onSearch($event: any) {
    this.paginator.firstPage()
    this.retrieveDevices()
  }

  handlePage(): void {
    this.retrieveDevices()
  }

  retrieveDevices(): void {
    const tagIds = this.tagsAdded.map(tagAdded => tagAdded.id as string)
    this.subscriptions.add(
      this.deviceService.getDevicesWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        tagIds,
        this.selectedScope,
        this.deviceSearch.value.search,
        this.statusAdded
      ).pipe(
        tap(invocationResult => {
          this.deviceDataSource.data = invocationResult.data
          this.paginator.length = invocationResult.pagination?.count as number
        }),
        mergeMap(invocationResult => from(invocationResult.data) as Observable<Device>),
        map((device: Device) => device.customerId),
        distinct(),
        mergeMap(customerId => {
          return this.customerService.getCustomer(customerId)
            .pipe(
              catchError(err => {
                return EMPTY // This allows the mergemap to continue even when the customerId isn't set for the device,
              }))
        }),
        toArray()
      ).subscribe(customers => {
        this.deviceCustomerNames = customers.reduce((accumulator: {[customerId: string]: string}, currentValue) => {
          if (currentValue.id) {
            accumulator[currentValue.id] = currentValue.name
          }
          return accumulator
        }, {})
        this.chRef.detectChanges()
      })
    )
  }

  openDeviceCreateDialog(): void {
    const deviceCreateDialogRef = this.matDialog.open(DeviceCreateDialogComponent)
    this.subscriptions.add(
      deviceCreateDialogRef.afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }

  doPaired(device: Device): void {
    const dialogRef = this.matDialog.open(DevicePairingDialogComponent, {
      width: "400px",
      data: {
        device
      },
      panelClass: 'device-pairing-dialog-container'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) { this.snackService.snackBarSuccess("DEVICE.PAIRING.SUCCESS") }
    })
  }

  doDelete(event: Event, device: Device): void {
    event.preventDefault()
    event.stopPropagation()
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("DEVICE_LIST_COMPONENT.DELETE") + " " + device.name
    }
    config.panelClass = "humecloud-dialog-container"
    const deleteDialogRef = this.matDialog.open(DeleteDialogComponent, config)
    this.subscriptions.add(
      deleteDialogRef.afterClosed().pipe(
        mergeMap(value => {
          if (value) {
            return this.deviceService.deleteDevice(device.id)
          }
          return of(true)
        })
      ).subscribe(cancelled => {
        if (!cancelled) {
          this.handlePage()
        }
      })
    )
  }

  doShowMonit(event: any, device: Device): void {
    const config = new MatDialogConfig()
    config.data = {
      device
    }
    this.matDialog.open(DeviceMonitDialogComponent, config)
  }

  switchViewMode(viewMode: ViewMode): void {
    if (viewMode == ViewMode.Grid) {
      this.viewMode = ViewMode.Grid
    } else {
      this.viewMode = ViewMode.List
    }
  }

  initializeTagsDropdownSettings() {
    this.dropdownTagsSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "id",
      textField: "name",
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: this.translateService.instant("APPS.SCREENLAB.TAG_DROPDOWN.NO_DATA_PLACEHOLDER"),
      searchPlaceholderText: this.translateService.instant("APPS.SCREENLAB.TAG_DROPDOWN.SEARCH_PLACEHOLDER")
    }
  }

  initializeStatusDropdownSettings() {
    this.dropdownStatusesSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "id",
      textField: "name",
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: false,
      noDataAvailablePlaceholderText: this.translateService.instant("APPS.SCREENLAB.STATUS_DROPDOWN.NO_DATA_PLACEHOLDER"),
      searchPlaceholderText: this.translateService.instant("APPS.SCREENLAB.STATUS_DROPDOWN.SEARCH_PLACEHOLDER")
    }
  }

  onTagSelected(tag: any) {
    this.tagsAdded.push(tag as Tag)
    this.retrieveDevices()
  }

  onTagDeSelected(tag: any) {
    this.tagsAdded = this.tagsAdded.filter(tagAdded => tagAdded.id !== (tag as Tag).id)
    this.retrieveDevices()
  }

  onStatusSelected(status: any) {
    this.statusAdded.push(DeviceConnectionStatus[(status as { id: string, name: string }).id as keyof typeof DeviceConnectionStatus])
    this.retrieveDevices()
  }

  onStatusDeSelected(status: any) {
    this.statusAdded = this.statusAdded.filter(statusAdded => {
      return DeviceConnectionStatus[statusAdded] !== (status as { id: string, name: string }).id
    })
    this.retrieveDevices()
  }

  doCreateAction(deviceId: string): void {
    const config = new MatDialogConfig()
    config.data = {
      deviceId
    }
    this.matDialog.open(DeviceActionDialogComponent, config)
  }

  onQueryScopeChange(event: MatSelectChange): void {
    this.selectedScope = event.value
    this.retrieveDevices()
  }


  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar
  }
}
